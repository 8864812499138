import React, { Suspense, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Grid, GridItem } from '@chakra-ui/react';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Router from 'components/Router';
import { EntityPageSkeletor } from 'components/Skeletons';
import { fetchAll as getConsts } from 'store/consts/consts.actions';
import { fetchAll as fetchAllDevicebrands } from 'store/devicebrands';
import { fetchAll as fetchAllDevices } from 'store/devices';
import { fetchAllOptimizations } from 'store/optimizations';
import { fetchAllModelsets } from 'store/modelsets';
import { fetchAllTargetings } from 'store/targetings';
import { fetchAllAdvertisers } from 'store/advertisers';
import { CAN_VIEW_MODELSETS, CAN_VIEW_TARGETINGS } from 'consts/permissions';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { fetchAllDeals } from 'store/deals';
import { fetchAllProposals } from 'store/proposals';
import { Helmet } from 'react-helmet-async';

const OnAuthorizedLoad = () => {
  const dispatch = useDispatch();
  const userCanViewModelsets = userHasRequiredPermissions([CAN_VIEW_MODELSETS]);
  const userCanViewTargetings = userHasRequiredPermissions([
    CAN_VIEW_TARGETINGS,
  ]);

  useEffect(() => {
    batch(() => {
      dispatch(getConsts());
      dispatch(fetchAllDevicebrands());
      dispatch(fetchAllDevices());
      dispatch(fetchAllAdvertisers());
      dispatch(fetchAllDeals());
      dispatch(fetchAllProposals());
    });
  }, []);

  useEffect(() => {
    if (!userCanViewModelsets) return;
    batch(() => {
      dispatch(fetchAllOptimizations());
      dispatch(fetchAllModelsets());
    });
  }, [userCanViewModelsets]);

  useEffect(() => {
    if (!userCanViewTargetings) return;
    dispatch(fetchAllTargetings());
  }, [userCanViewTargetings]);

  return null;
};

function App() {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const theme = process.env.REACT_APP_THEME
    ? JSON.parse(process.env.REACT_APP_THEME)
    : null;

  return (
    <>
      <Helmet>
        <title>{theme ? theme?.title : 'AdFusion'}</title>
        <link rel='icon' href={theme ? theme?.favicon : '/favicon.ico'} />
        <link
          rel='apple-touch-icon'
          href={theme ? theme?.appleFavicon : '/logo192.png'}
        />
      </Helmet>
      <Grid
        h='100vh'
        templateAreas={`
            "sidebar header"
            "sidebar body"
          `}
        templateColumns='auto 1fr'
        templateRows='auto 1fr'
      >
        {isLoggedIn && (
          <>
            <OnAuthorizedLoad />
            <Sidebar gridArea='sidebar' />
            <Header gridArea='header' />
          </>
        )}
        <GridItem gridArea='body' minWidth={0} overflow='hidden'>
          <Suspense fallback={<EntityPageSkeletor />}>
            <Router />
          </Suspense>
        </GridItem>
      </Grid>
    </>
  );
}

export default App;
