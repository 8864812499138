import React from 'react';
import { Helmet } from 'react-helmet-async';

export type TitleProps = {
  name: string | number;
};

export type ScritpProps = {
  src: string;
};

export const Title = ({ name }: TitleProps): JSX.Element => {
  const theme = process.env.REACT_APP_THEME
    ? JSON.parse(process.env.REACT_APP_THEME)
    : null;
  return (
    <Helmet>
      <title>
        {name ? name + ' | ' : ''}
        {theme ? theme?.title : 'AdFusion'}
      </title>
    </Helmet>
  );
};

export const Script = ({ src }: ScritpProps): JSX.Element => {
  return (
    <Helmet>
      <script src={src} />
    </Helmet>
  );
};
