import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import adFusionLogo from '../AdFusion_logo.png';

const Logo = ({ ...props }) => {
  const logo = process.env.REACT_APP_THEME
    ? JSON.parse(process.env.REACT_APP_THEME)
    : null;
  return (
    <Box {...props}>
      <img src={logo ? logo?.sidebarLogo : adFusionLogo} />
    </Box>
  );
};

Logo.propTypes = {
  variant: PropTypes.string,
};

export default Logo;
